import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { updateClient, getClientData } from '../firebase/obj/client'
import { getCurrentUser } from '../firebase/obj/user'
import ReactMarkdown from 'react-markdown'
import Title from '../components/title'
import { makeStyles } from '@material-ui/core/styles'
import useMainContext from '../hooks/useMainContext'

const useStyles = makeStyles(theme => ({
    info: {
        height: '50vh',
        overflowY: 'scroll',
        border: '1px solid #DDDDDD',
        borderRadius: 5,
        padding: 10,
        backgroundColor: '#F2F2F2'
    }
}))



export const TermsConditionsWithData = () => {
    const { client } = useMainContext()
    const [accepted, setAccepted] = useState(false)
    const [terms, setTerms] = useState('')
    const [privacity, setPrivacity] = useState('')

    const classes = useStyles()

    useEffect(() => {
        const { uid, termsConditions } = client || {}
        const _accepted = window.sessionStorage.getItem(`termsConditions-${uid}`) === 'true' ? true : false
        if (termsConditions || _accepted) {
            setAccepted(true)
        } else {
            setAccepted(false)
        }
    }, [client])

    useEffect(() => {
        loadTerms()
        loadPrivacity()
    }, [])

    const loadTerms = () => {
        const user = 'brianbentancourt'
        const repo = 'burgerhousePublic'
        const path = 'termsConditions.md'
        const url = `https://api.github.com/repos/${user}/${repo}/contents/${path}`
        const myRequest = new Request(url, { headers: new Headers({ 'accept': 'application/vnd.github.v3.raw' }) })
        fetch(myRequest)
            .then(function (response) {
                if (!response.ok) return "# There was error with your response, please check the details and try again"
                return response.text()
            })
            .then(function (response) {
                //document.querySelector('main').innerHTML = md.render(response)
                setTerms(response)
            })
    }

    const loadPrivacity = () => {
        const user = 'brianbentancourt'
        const repo = 'burgerhousePublic'
        const path = 'privacity.md'
        const url = `https://api.github.com/repos/${user}/${repo}/contents/${path}`
        const myRequest = new Request(url, { headers: new Headers({ 'accept': 'application/vnd.github.v3.raw' }) })
        fetch(myRequest)
            .then(function (response) {
                if (!response.ok) return "# There was error with your response, please check the details and try again"
                return response.text()
            })
            .then(function (response) {
                //document.querySelector('main').innerHTML = md.render(response)
                setPrivacity(response)
            })
    }

    const handleAccept = () => {
        const { uid } = getCurrentUser()
        const _client = {
            ...client,
            termsConditions: true,
            termsConditionsDate: new Date()
        }

        updateClient(_client)
            .then(response => {
                window.sessionStorage.setItem(`termsConditions-${uid}`, `true`)
                setAccepted(true)
            })
            .catch(error => console.error(error))
    }

    return (
        <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12} className={classes.title}>
                <Title>Términos y condiciones</Title>
            </Grid>
            <Grid item xs={12} className={classes.info}>
                <ReactMarkdown children={terms} />
            </Grid>

            <Grid item xs={12} className={classes.title} style={{ marginTop: 50 }}>
                <Title>Política de Privacidad para aplicaciones móviles</Title>
            </Grid>
            <Grid item xs={12} className={classes.info}>
                <ReactMarkdown children={privacity} />
            </Grid>

            <Grid item xs={12} className={classes.button} style={{ marginTop: 50 }}>
                {
                    accepted ?
                        <MuiAlert severity="success">Ya ha aceptado nuestros Términos, Condiciones y Política de privacidad. Siga disfrutando de su experiencia en {process.env.REACT_APP_NAME}!</MuiAlert>
                        :
                        <Button disabled={accepted} color="primary" variant="contained" onClick={handleAccept}>Acepto Terminos, Condiciones y Política de privacidad</Button>
                }

            </Grid>
        </Grid>
    )
}

export default TermsConditionsWithData