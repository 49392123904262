import React, { useEffect, useState } from 'react'
import {
    CardContent,
    CardActionArea,
    Typography,
    Card,
    Grid,
    Button,
    Tooltip
} from '@material-ui/core'
import { useStyles } from './cardStyles'
import { Link, navigate } from '@reach/router'
import useOrder from '../../../hooks/useOrderContext'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const steps = [
    'Pendiente',
    'En preparación',
    'En reparto',
    'Entregado',
    'Cancelado'
]

export const OrderViewCard = ({ order, config, mode = 'card' }) => {
    const classes = useStyles()
    const { repeatOrder } = useOrder()
    //const [orderState, setOrderState] = useState('Pendiente')
    const [activeStep, setActiveStep] = useState(0)

    useEffect(() => {
        const { orderState } = order
        let { firstState, delayState, cookedState, assignedState, lastState, canceledState } = config
        let step = 0
        if (orderState === firstState || orderState === delayState)
            step = 1
        if (orderState === cookedState || orderState === assignedState)
            step = 2
        if (orderState === lastState)
            step = 3
        if (orderState === canceledState)
            step = 4

        setActiveStep(step)
    }, [order, config])

    const handleRepeatOrder = () => {
        repeatOrder(order)
        navigate("/carrito")
    }

    const cardContent = () =>
    (
        <CardContent>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.receiptContainer}
            >
                <Grid item xs={6}>
                    <Typography  >Cod: {order.cod}</Typography>
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                    <Typography> {moment(new Date(order.date.seconds * 1000)).format('DD/MM/YYYY HH:mm')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography  >Estado: {steps[activeStep] || order.orderState || ''}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {
                        order?.cancelledMessage && <Typography>Motivo: {order.cancelledMessage}</Typography>
                    }
                </Grid>
                <Grid item xs={12} container style={{ padding: 10 }}>
                    {
                        order?.productsList?.map((product, index) => (
                            <Grid container key={index} >
                                <Grid item xs={1}>
                                    <Typography>{product.qty}</Typography>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography >{product.name} </Typography>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography  >Total: ${order.amount || 0}</Typography>
                </Grid>

                {
                    mode === 'history' &&
                    <Grid item xs={12} style={{ paddingTop: 10 }}>
                        <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            fullWidth
                            disabled={false}
                            onClick={handleRepeatOrder}
                        >Repetir pedido</Button>
                    </Grid>
                }

            </Grid>
        </CardContent>

    )



    return (
        <Card className={classes.root}>
            {
                mode === 'history' ?
                    cardContent() :
                    <CardActionArea component={Link} to={`/order/${order.id}`}>
                        {cardContent()}
                    </CardActionArea>
            }
        </Card>
    )
}

export default OrderViewCard
